export default function Teams({isHovered, color, colorHover}) {
    return (<svg fill={isHovered ? colorHover : color} height="70" width="70" xmlns="http://www.w3.org/2000/svg"
                 style={{margin: '-25px -15px'}}
                 viewBox="0 0 40 40">
        <g>
            <g>
                <g>
                    <g>
                        <g>
                            <path d="M20,21.9c-1.5,0-2.7-1.3-2.7-3s1.2-3,2.7-3s2.7,1.3,2.7,3S21.5,21.9,20,21.9z M20,16.9c-0.9,0-1.7,0.9-1.7,2
s0.7,2,1.7,2s1.7-0.9,1.7-2S20.9,16.9,20,16.9z"/>
                        </g>
                        <g>
                            <path d="M21.1,20.6h0.2c1.6,0,2.9,1.3,2.9,2.9v2.1c0,0.3-0.2,0.5-0.5,0.5s-0.5-0.2-0.5-0.5v-2.1c0-1-0.9-1.9-1.9-1.9h-0.2V20.6
						z"/>
                        </g>
                        <g>
                            <path d="M18.9,21.6h-0.2c-1,0-1.9,0.9-1.9,1.9v2.1c0,0.3-0.2,0.5-0.5,0.5s-0.5-0.2-0.5-0.5v-2.1c0-1.6,1.3-2.9,2.9-2.9h0.2
						V21.6z"/>
                        </g>
                    </g>
                    <g>
                        <g>
                            <g>
                                <path d="M25.5,19.9c-1.2,0-2.3-1.1-2.3-2.5s1-2.5,2.3-2.5s2.3,1.1,2.3,2.5S26.7,19.9,25.5,19.9z M25.5,15.9
							c-0.7,0-1.3,0.7-1.3,1.5s0.6,1.5,1.3,1.5s1.3-0.7,1.3-1.5S26.2,15.9,25.5,15.9z"/>
                            </g>
                            <g>
                                <path d="M26.4,18.7h0.2c1.3,0,2.4,1.1,2.4,2.4v1.7c0,0.3-0.2,0.5-0.5,0.5S28,23.1,28,22.8v-1.7c0-0.8-0.6-1.4-1.4-1.4h-0.2
							V18.7z"/>
                            </g>
                            <g>
                                <path
                                    d="M24.6,19.7h-0.2c-0.8,0-1.4,0.6-1.4,1.4c0,0.3-0.2,0.5-0.5,0.5S22,21.4,22,21.1c0-1.3,1.1-2.4,2.4-2.4h0.2V19.7z"/>
                            </g>
                        </g>
                        <g>
                            <g>
                                <path d="M14.5,19.9c-1.2,0-2.3-1.1-2.3-2.5s1-2.5,2.3-2.5s2.3,1.1,2.3,2.5S15.7,19.9,14.5,19.9z M14.5,15.9
							c-0.7,0-1.3,0.7-1.3,1.5s0.6,1.5,1.3,1.5s1.3-0.7,1.3-1.5S15.2,15.9,14.5,15.9z"/>
                            </g>
                            <g>
                                <path
                                    d="M15.4,18.7h0.2c1.3,0,2.4,1.1,2.4,2.4c0,0.3-0.2,0.5-0.5,0.5S17,21.4,17,21.1c0-0.8-0.6-1.4-1.4-1.4h-0.2V18.7z"/>
                            </g>
                            <g>
                                <path d="M13.6,19.7h-0.2c-0.8,0-1.4,0.6-1.4,1.4v1.7c0,0.3-0.2,0.5-0.5,0.5S11,23.1,11,22.8v-1.7c0-1.3,1.1-2.4,2.4-2.4h0.2
							V19.7z"/>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>);
}
