export default function Home({isHovered, color, colorHover}) {
    return (<svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M14.4829 5.05916C13.3303 4.11288 11.6697 4.11288 10.5171 5.05915L4.92805 9.64768C4.44605 10.0434 4.16667 10.6342 4.16667 11.2579V18.7501C4.16667 19.9007 5.09941 20.8334 6.25 20.8334H18.75C19.9006 20.8334 20.8333 19.9007 20.8333 18.7501V11.2579C20.8333 10.6342 20.554 10.0434 20.072 9.64768L14.4829 5.05916ZM9.19513 3.44896C11.1161 1.87183 13.8839 1.87183 15.8049 3.44896L21.3939 8.03748C22.3579 8.82892 22.9167 10.0106 22.9167 11.2579V18.7501C22.9167 21.0513 21.0512 22.9168 18.75 22.9168H6.25C3.94882 22.9168 2.08334 21.0513 2.08334 18.7501V11.2579C2.08334 10.0106 2.64209 8.82892 3.6061 8.03748L9.19513 3.44896Z"
                  fill={isHovered ? colorHover : color}/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M8.33334 16.6667C8.33334 14.9409 9.73245 13.5417 11.4583 13.5417H13.5417C15.2676 13.5417 16.6667 14.9409 16.6667 16.6667V21.8751H14.5833V16.6667C14.5833 16.0915 14.117 15.6251 13.5417 15.6251H11.4583C10.883 15.6251 10.4167 16.0915 10.4167 16.6667V21.8751H8.33334V16.6667Z"
                  fill={isHovered ? colorHover : color}/>
        </svg>

    );
}
